<template>
  <div>
    <c-search-box @enter="getDeptList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 제목 -->
          <c-text
            label="부서"
            name="deptNm"
            placeholder="부서명"
            v-model="searchParam.deptNm">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-tree-table
          title="부서목록"
          parentProperty="upDeptCd"
          customID="deptCd"
          :columns="gridDept.columns"
          :data="gridDept.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getDeptList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="부서정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteData"
                  @btnCallback="deleteCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :required="true"
                  label="부서명"
                  name="deptName"
                  type="edit"
                  v-model="data.deptName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-plant type="edit" :required="true" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :required="true"
                  label="부서코드"
                  name="deptCd"
                  type="edit"
                  v-model="data.deptCd">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-dept type="edit" label="상위부서" name="upDeptCd" v-model="data.upDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :required="true"
                  label="순번"
                  name="sortOrder"
                  type="edit"
                  v-model="data.sortOrder">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dept-manage',
  data() {
    return {
      gridDept: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptNm: '',
        useFlag: 'Y',
      },
      data: {
        plantCd: null,
        deptCd: '',
        deptName: '',
        upDeptCd: '',
        sortOrder: '',
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.dept.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedDeptCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.dept.list.url;
      this.detailUrl = selectConfig.mdm.dept.get.url;
      this.checkUrl = selectConfig.mdm.dept.check.url;
      this.insertUrl = transactionConfig.mdm.dept.insert.url;
      this.updateUrl = transactionConfig.mdm.dept.update.url;
      this.deleteUrl = transactionConfig.mdm.dept.delete.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getDeptList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridDept.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedDeptCd = row.deptCd;
      this.$http.url = this.$format(this.detailUrl, this.selectedDeptCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.data = {
        plantCd: '',
        deptCd: '',
        deptName: '',
        upDeptCd: this.selectedDeptCd,
        sortOrder: '',
        useFlag: 'Y',
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.data = {
        plantCd: '',
        deptCd: '',
        deptName: '',
        upDeptCd: '',
        sortOrder: '',
        useFlag: '',
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.deptCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.deptCd);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            console.log(_result)
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ deptCd: result.data.deptCd })
      } else {
        this.getDeptList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDeptList();
    },
  }
};
</script>
